import {
  Badge,
  HStack,
  IconButton,
  Switch,
  Td,
  Text,
  Tooltip,
  Tr,
} from "@chakra-ui/react";
import { useGlobalContext } from "GlobalContext";
import { useState } from "react";
import { FaExternalLinkAlt } from "react-icons/fa";
import { IoCopyOutline } from "react-icons/io5";
import { toast } from "react-toastify";
import { createConfig, editConfig } from "services/config";
import { Mixpanel } from "services/mixpanel";
import ProtectedComponent from "shared/components/ProtectedComponent";
import { copyToClipboard } from "shared/helpers/clipboard";
import { capitalizeFirstLetter } from "shared/helpers/string";
import { usePermissions } from "shared/hooks/usePermissions";
import { Config } from "shared/models/config";
import { useAppTabContext } from "../../../AppContext";
import EditModal from "./EditModal";

interface ConfigRowProps {
  config: Config;
}

const ConfigRow: React.FC<ConfigRowProps> = ({ config }) => {
  const { d } = usePermissions();
  const { confirm } = useGlobalContext();
  const { app, setLoadingConfigs, updateConfig } = useAppTabContext();

  const [openEditModal, setOpenEditModal] = useState(false);

  const copyToken = () => copyToClipboard(config.trueValue, "Token copiado");

  const openEdit = () => {
    Mixpanel.track("customers-app-configs-opened-edit-value");
    setOpenEditModal(true);
  };

  const onChangeSwitch = () => {
    confirm(
      `${config.booleanValue ? "Desabilitar" : "Habilitar"} configuração`,
      "Deseja continuar?",
      () => {
        setLoadingConfigs(true);

        Mixpanel.track("customers-app-configs-clicked-toggle");

        // If code exists edit existing config
        if (config.code)
          editConfig(config.code, {
            codigo: config.code,
            idi: app.idi,
            nome: config.name,
            valor: config.getTrueTypeOf(!config.booleanValue),
            updated_at: config.updatedAt!,
            created_at: config.createdAt!,
          })
            .then((response) => {
              toast.success(
                `Configuração ${
                  config.booleanValue ? "desabilitada" : "habilitada"
                }`
              );
              updateConfig(response.data);
            })
            .catch(() =>
              toast.error(
                `Falha ao ${
                  config.booleanValue ? "desabilitar" : "habilitar"
                } configuração`
              )
            )
            .finally(() => setLoadingConfigs(false));
        // Else create new config, following licenciamento flow
        else
          createConfig(
            app.idi,
            config.name,
            config.getTrueTypeOf(!config.booleanValue)
          )
            .then((response) => {
              toast.success(
                `Configuração ${
                  config.booleanValue ? "desabilitada" : "habilitada"
                }`
              );
              updateConfig(response.data);
            })
            .catch(() =>
              toast.error(
                `Falha ao ${
                  config.booleanValue ? "desabilitar" : "habilitar"
                } configuração`
              )
            )
            .finally(() => setLoadingConfigs(false));
      }
    );
  };

  return (
    <Tr>
      <EditModal
        isOpen={openEditModal}
        onClose={() => setOpenEditModal(false)}
        config={config}
      />
      <Td textAlign="center">{capitalizeFirstLetter(config.domain)}</Td>
      <Td>{config.name}</Td>
      <Td>
        <Badge
          rounded="full"
          fontWeight="500"
          w="100%"
          px="0.5rem"
          textAlign="center"
          colorScheme={config.type === "boolean" ? "yooga" : "gray"}
        >
          {config.type === "boolean" ? "Botão habilitar" : "Valor manual"}
        </Badge>
      </Td>
      <Td>
        {config.type === "token" && !!config.trueValue && (
          <HStack>
            <Text overflow="hidden" textOverflow="ellipsis" maxW="5rem">
              {config.trueValue}
            </Text>
            <IconButton
              aria-label="Copy value"
              size="sm"
              variant="link"
              onClick={copyToken}
              icon={<IoCopyOutline />}
            />
          </HStack>
        )}
      </Td>
      <Td>
        <Tooltip label={config.description}>
          <Text
            overflow="hidden"
            textOverflow="ellipsis"
            fontSize="0.8rem"
            maxW="10rem"
          >
            {config.description}
          </Text>
        </Tooltip>
      </Td>
      <Td textAlign="center">
        {config.type === "boolean" && (
          <ProtectedComponent permission="installation-card-configs-update">
            <HStack>
              <Switch
                size="sm"
                isChecked={config.booleanValue}
                onChange={onChangeSwitch}
                isDisabled={d("installation-card-configs-update")}
              />
              <Text fontSize="0.8rem">
                {config.booleanValue ? "Ativado" : "Desativado"}
              </Text>
            </HStack>
          </ProtectedComponent>
        )}
      </Td>
      <Td textAlign="center">
        <ProtectedComponent permission="installation-card-configs-update">
          <IconButton
            aria-label="Ver detalhes"
            icon={<FaExternalLinkAlt />}
            variant="ghost"
            size="sm"
            onClick={openEdit}
            isDisabled={
              config.type !== "token" || d("installation-card-configs-update")
            }
            visibility={config.type !== "token" ? "hidden" : "visible"}
          />
        </ProtectedComponent>
      </Td>
    </Tr>
  );
};

export default ConfigRow;
