import {
  Card,
  CardBody,
  CardHeader,
  CircularProgress,
  Flex,
  Heading,
  HStack,
  Icon,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { useEffect, useMemo, useState } from "react";
import { FiSettings } from "react-icons/fi";
import { Config } from "shared/models/config";
import { ConfigDomain } from "shared/types/configs";
import { useAppTabContext } from "../../AppContext";
import ConfigRow from "./components/ConfigRow";
import FiltersHeader from "./components/FiltersHeader";
import PaginationFooter from "./components/PaginationFooter";
import filterConfigs from "./helpers/filter";
import { paginateConfigs } from "./helpers/paginate";

const Configs = () => {
  const { rawConfigs, loadingConfigs } = useAppTabContext();

  const [search, setSearch] = useState("");
  const [isToken, setIsToken] = useState(false);
  const [isBoolean, setIsBoolean] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [isNotActive, setIsNotActive] = useState(false);
  const [domain, setDomain] = useState<ConfigDomain>();

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const filteredConfigs: Config[] = useMemo(
    () =>
      filterConfigs(
        rawConfigs,
        search,
        isToken,
        isBoolean,
        isActive,
        isNotActive,
        domain
      ),
    [rawConfigs, search, isToken, isBoolean, isActive, isNotActive, domain]
  );

  const configs: Config[] = useMemo(
    () => paginateConfigs(filteredConfigs, page, pageSize),
    [filteredConfigs, page, pageSize]
  );

  useEffect(() => {
    setPage(1);
  }, [filteredConfigs, pageSize]);

  return (
    <Card>
      <CardHeader>
        <HStack align="center">
          <Icon as={FiSettings} />
          <Heading size="sm">Configurações</Heading>
        </HStack>
      </CardHeader>

      <CardBody as={Stack} gap="1rem">
        <FiltersHeader
          search={search}
          setSearch={setSearch}
          isToken={isToken}
          setIsToken={setIsToken}
          isBoolean={isBoolean}
          setIsBoolean={setIsBoolean}
          isActive={isActive}
          setIsActive={setIsActive}
          isNotActive={isNotActive}
          setIsNotActive={setIsNotActive}
          domain={domain}
          setDomain={setDomain}
        />

        <TableContainer>
          <Table variant="simple" size="sm">
            <Thead>
              <Tr>
                <Th textAlign="center">Categoria</Th>
                <Th textAlign="center">Nome</Th>
                <Th textAlign="center">Tipo</Th>
                <Th textAlign="center">Valor</Th>
                <Th textAlign="center">Descrição</Th>
                <Th textAlign="center">Habilitar</Th>
                <Th textAlign="center">Ver detalhes</Th>
              </Tr>
            </Thead>
            <Tbody>
              {loadingConfigs ? (
                <Tr>
                  <Td colSpan={7}>
                    <Flex justify="center" py="1rem">
                      <CircularProgress isIndeterminate color="yooga.400" />
                    </Flex>
                  </Td>
                </Tr>
              ) : (
                configs.map((config) => (
                  <ConfigRow key={config.name} config={config} />
                ))
              )}
            </Tbody>
          </Table>
        </TableContainer>

        <PaginationFooter
          totalLength={filteredConfigs.length}
          visibleLength={configs.length}
          page={page}
          setPage={setPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
        />
      </CardBody>
    </Card>
  );
};

export default Configs;
