import { createContext, useContext, useEffect, useState } from "react";
import { getRoboFiscal } from "services/app";
import RoboFiscal from "shared/models/roboFiscal";
import { useAppTabContext } from "../../AppContext";
import Fiscal from "./Fiscal";

interface FiscalContextData {
  roboFiscal: RoboFiscal | null;
  loading: boolean;
  setLoading(b: boolean): void;
}

const Context = createContext<FiscalContextData>({} as FiscalContextData);

export const useFiscalContext = () => useContext(Context);

const FiscalContext = () => {
  const { app } = useAppTabContext();

  const [loading, setLoading] = useState(true);

  const [roboFiscal, setRoboFiscal] = useState<RoboFiscal | null>(null);

  useEffect(() => {
    if (app.fiscal === 1) {
      getRoboFiscal(app.document.replace(/\D/g, ""))
        .then((res) => {
          const robot = new RoboFiscal(res.data);
          setRoboFiscal(robot);
        })
        .catch((error) => {
          console.error("Falha ao carregar robo fiscal", error);
          setRoboFiscal(null);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setRoboFiscal(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const data: FiscalContextData = {
    roboFiscal,
    loading,
    setLoading,
  };

  return (
    <Context.Provider value={data}>
      <Fiscal />
    </Context.Provider>
  );
};

export default FiscalContext;
