import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Divider,
  Grid,
  GridItem,
  Heading,
  HStack,
  Icon,
  Radio,
  RadioGroup,
  Spacer,
  Switch,
  Tag,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { useGlobalContext } from "GlobalContext";
import { useEffect, useState } from "react";
import { PiCurrencyCircleDollar } from "react-icons/pi";
import { toast } from "react-toastify";
import { disableMarketplacePayment } from "services/integratedPayment";
import { usePermissions } from "shared/hooks/usePermissions";
import { useAppTabContext } from "../../AppContext";
import { useIntegratedPaymentContext } from "./IntegratedPaymentContext";

const IntegratedPayment = () => {
  const { d } = usePermissions();
  const { confirm } = useGlobalContext();
  const { app } = useAppTabContext();
  const { loading, marketplace, getDriver, isRegistered, handleSubmit } =
    useIntegratedPaymentContext();

  const [initialValue, setInitialValue] = useState<string>("1");
  const [currentValue, setCurrentValue] = useState<string>("1");
  const [isPixEnabled, setIsPixEnabled] = useState<boolean>(
    getDriver ? getDriver.enabled : false
  );

  const determineInitialValue = () => {
    if (!getDriver) return "1";
    if (
      getDriver.methods[0].enabled === true &&
      getDriver.methods[0].transactionFeeValue === 1
    )
      return "1";
    if (
      getDriver.methods[0].enabled === true &&
      getDriver.methods[0].transactionFeeValue === 0.2
    )
      return "2";
    if (
      getDriver.methods[0].enabled === true &&
      getDriver.methods[0].transactionFeeValue === 0.9
    )
      return "3";
    if (
      getDriver.methods[0].enabled === true &&
      getDriver.methods[0].transactionFeeValue === 0.6
    )
      return "4";
    return "1";
  };

  const handleChangeValue = (newValue: string) => {
    setCurrentValue(newValue);
  };

  const handleToggleEnablePix = () => {
    if (isPixEnabled) {
      confirm(`Desativar PIX`, "Deseja continuar?", () => {
        disableMarketplacePayment(String(app.idi))
          .then(() => {
            toast.success("Configurações do PIX atualizadas com sucesso");
            setIsPixEnabled(false);
          })
          .catch((error) => {
            toast.error(
              `Não foi possível atualizar as configurações do PIX: ${error}`
            );
          });
      });
    } else {
      setIsPixEnabled(true);
    }
  };

  const isDisabled =
    !isPixEnabled || d("installation-card-integrated_payment-update");

  const tooltipMessage = !isPixEnabled
    ? "O PIX deve estar habilitado para salvar"
    : "Você não tem permissão para salvar";

  useEffect(() => {
    const initial = determineInitialValue();
    setInitialValue(initial);
    setCurrentValue(initial);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDriver]);

  useEffect(() => {
    if (getDriver) {
      setIsPixEnabled(getDriver.enabled);
    }
  }, [getDriver]);

  const onSubmit = () => {
    handleSubmit(isPixEnabled, currentValue, initialValue);
  };

  return (
    <Card>
      <CardHeader pb="0">
        <HStack justify="space-between">
          <HStack>
            <Icon as={PiCurrencyCircleDollar} />
            <Heading size="sm">Pagamento Integrado</Heading>
          </HStack>
        </HStack>
      </CardHeader>

      <CardBody>
        {marketplace !== null && isRegistered ? (
          <>
            <HStack mb="4">
              <Switch
                size="md"
                id="pix-switch"
                isChecked={isPixEnabled}
                onChange={handleToggleEnablePix}
                isDisabled={d("installation-card-integrated_payment-disable")}
              />
              <Text fontWeight="bold">PIX</Text>
            </HStack>
            <Divider my="1rem" />

            <HStack mb="4">
              <Text fontWeight="bold" mb="2">
                Taxas
              </Text>
            </HStack>

            <RadioGroup onChange={handleChangeValue} value={currentValue}>
              <Grid templateColumns="repeat(2, 1fr)" gap={4}>
                <GridItem
                  border="1px solid"
                  borderColor="gray.200"
                  borderRadius="md"
                  p="4"
                  alignContent={"center"}
                >
                  <HStack>
                    <Radio value="1" />
                    <Text>R$ 1,00</Text>
                    <Spacer />
                    <Tag>Indicado para ticket médio maior que R$ 50,00</Tag>
                  </HStack>
                </GridItem>

                <GridItem
                  border="1px solid"
                  borderColor="gray.200"
                  borderRadius="md"
                  p="4"
                  alignContent={"center"}
                >
                  <HStack>
                    <Radio value="2" />
                    <Text>R$ 0,20 + 1,67%</Text>
                    <Spacer />
                    <Tag textAlign="center">
                      Indicado para ticket médio menor que R$ 50,00
                    </Tag>
                  </HStack>
                </GridItem>

                <GridItem
                  border="1px solid"
                  borderColor="gray.200"
                  borderRadius="md"
                  p="4"
                  alignContent={"center"}
                >
                  <HStack>
                    <Radio value="3" />
                    <Text>R$ 0,90</Text>
                    <Spacer />
                    <Tag>Utilizado por algumas franquias</Tag>
                  </HStack>
                </GridItem>

                <GridItem
                  border="1px solid"
                  borderColor="gray.200"
                  borderRadius="md"
                  p="4"
                  alignContent={"center"}
                >
                  <HStack>
                    <Radio value="4" />
                    <Text>R$ 0,60</Text>
                    <Spacer />
                    <Tag>Plano promocional</Tag>
                  </HStack>
                </GridItem>
              </Grid>
            </RadioGroup>

            <Box mt="6" textAlign="center">
              <Tooltip label={tooltipMessage} isDisabled={!isDisabled} hasArrow>
                <Button
                  variant="filled"
                  size="lg"
                  w="100%"
                  type="submit"
                  isDisabled={
                    !isPixEnabled ||
                    d("installation-card-integrated_payment-update")
                  }
                  isLoading={loading}
                  onClick={onSubmit}
                >
                  Salvar
                </Button>
              </Tooltip>
            </Box>
          </>
        ) : (
          <Text fontWeight="500" color="gray.500">
            Cadastro ainda não realizado. Faça o credenciamento pelo app da
            Yooga.
          </Text>
        )}
      </CardBody>
    </Card>
  );
};

export default IntegratedPayment;
