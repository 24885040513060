import { Box, Flex, Heading, Stack, Text } from "@chakra-ui/react";
import { PaymentMethods } from "shared/types/payment";
import { useOrderModalContext } from "../ModalContext";

const OrderData = () => {
  const { order } = useOrderModalContext();

  return (
    <Stack>
      <Heading size="sm">Detalhes da Cobrança</Heading>
      <Flex wrap="wrap" rowGap="2rem" mt="1rem">
        <Box flexBasis="50%">
          <Text variant="label">Data de Vencimento</Text>
          <Text>{order?.due_date?.format("DD/MM/YYYY")}</Text>
        </Box>

        <Box flexBasis="50%">
          <Text variant="label">Preço</Text>
          <Text>{order?.priceString}</Text>
        </Box>

        {order?.bankId && (
          <Box flexBasis="50%">
            <Text variant="label">Método de pagamento</Text>
            <Text>{PaymentMethods[order.bankId]}</Text>
          </Box>
        )}
      </Flex>
    </Stack>
  );
};

export default OrderData;
