import { Box, Flex, Image } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { loginBanner1, loginBanner2, loginBanner3 } from "assets/images";
import Form from "./Form";

const Login = () => {
  const [selectedImage, setSelectedImage] = useState();

  useEffect(() => {
    const images = [loginBanner1, loginBanner2, loginBanner3];
    const randomImage = images[Math.floor(Math.random() * images.length)];
    setSelectedImage(randomImage);
  }, []);

  return (
    <Flex h="100vh">
      <Box flex="1" display={{ base: "none", md: "block" }}>
        <Image src={selectedImage} fit="cover" w="100%" h="100%" />
      </Box>
      <Box flex="1" shadow="rgba(0, 0, 0, 0.35) -5px 0px 15px">
        <Form />
      </Box>
    </Flex>
  );
};

export default Login;
