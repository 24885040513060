import { AxiosResponse } from "axios";
import { onlyNumbers } from "shared/helpers/string";
import { IApp } from "shared/models/app";
import { IAppUser } from "shared/models/appUser";
import { IInstallationStatus } from "shared/models/installationStatus";
import { apiNestNode, apiV2, licenciamento, nodeApi } from "./http";
import { AppInfoRequestData, QuickConfigsRequestData } from "./interfaces/app";

export function searchApps(query: string): Promise<AxiosResponse<IApp[]>> {
  return licenciamento.get<IApp[]>("app", { params: { b: 5, s: query } });
}

export function searchAppsWithYoogaCustomerId(
  query: string,
  yoogaCustomerId: string
): Promise<AxiosResponse<IApp[]>> {
  return licenciamento.get<IApp[]>("app", {
    params: { b: 5, s: query, yci: yoogaCustomerId },
  });
}

export function getAppUsers(idi: number): Promise<AxiosResponse<IAppUser[]>> {
  return licenciamento.get<IAppUser[]>(`usuarioapp/${idi}`);
}

export function changeUserPassword(
  user: IAppUser
): Promise<AxiosResponse<IAppUser>> {
  return licenciamento.put<IAppUser>(`usuarioapp/${user.codigo}`, user);
}

export function createTempUser(
  idi: number,
  login: string,
  token: string
): Promise<AxiosResponse<IAppUser>> {
  return licenciamento.post<IAppUser>("usuarioapp/temp", {
    idi,
    login,
    token,
    nome: "Usuário",
    sobrenome: "Temporário",
    timezone: "America/Sao_Paulo",
    usuario_del: null,
    codigo: null,
  });
}

export function updateApp(payload: IApp): Promise<AxiosResponse<IApp>> {
  return licenciamento.put(`app/${payload.idi}`, payload);
}

export function updateAppInfo(
  idi: number,
  payload: AppInfoRequestData
): Promise<AxiosResponse> {
  return apiNestNode.put(`installation/${idi}`, payload);
}

export function unlockTrustly(
  idi: number
): Promise<AxiosResponse<{ message: string }>> {
  return licenciamento.post(`app/billing-unlock/${idi}`, {});
}

export function unlockTemporary(
  idi: number,
  customerId: number
): Promise<AxiosResponse<{ message: string }>> {
  return apiNestNode.post(`temporary-unlock-installation/${idi}`, {
    customerId,
  });
}

export function appReset(
  payload: QuickConfigsRequestData
): Promise<AxiosResponse> {
  return nodeApi.post("instalacoes/resetdata", {
    ...payload,
    copy_secret: "p8PfF2QWzp9u9MYiuxLKYcTS8fIzZ0Wn",
  });
}

export function blockApp(
  idi: number,
  customerId: number
): Promise<AxiosResponse> {
  return apiNestNode.post(`block-installation/${idi}`, { customerId });
}

export function blockAllApps(
  document: string,
  customerId: number
): Promise<AxiosResponse> {
  return apiNestNode.post(`block-all-installations/${onlyNumbers(document)}`, {
    customerId,
  });
}

export function getInstallationStatusHistory(
  idi: number
): Promise<AxiosResponse<IInstallationStatus[]>> {
  return apiV2.get(`installation/${idi}`);
}

export function getRoboFiscal(cnpj: string): Promise<AxiosResponse> {
  return apiV2.get(`installation/fiscal/robo/${cnpj}`);
}
