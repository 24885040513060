import {
  Card,
  CardBody,
  CardHeader,
  Divider,
  Flex,
  Heading,
  HStack,
  Icon,
  List,
  ListIcon,
  ListItem,
  Stack,
  StackDivider,
  TabPanel,
  Tag,
  TagLabel,
  TagLeftIcon,
  Text,
} from "@chakra-ui/react";
import moment from "moment";
import { FaCircle, FaCircleCheck, FaRegFileLines } from "react-icons/fa6";
import { FiEdit } from "react-icons/fi";
import { IoCopyOutline } from "react-icons/io5";
import { Mixpanel } from "services/mixpanel";
import ProtectedButton from "shared/components/ProtectedButton";
import { copyToClipboard } from "shared/helpers/clipboard";
import { useAppTabContext } from "../../AppContext";
import { useFiscalContext } from "./FiscalContext";

const Fiscal = () => {
  const { app, openEditFiscalModal } = useAppTabContext();
  const { roboFiscal, loading } = useFiscalContext();

  const copyToken = () => {
    Mixpanel.track("customers-app-fiscal-clicked-copy-token");
    copyToClipboard(app.fiscalToken, "Token copiado");
  };

  const checkLastStatus = (status: number) => {
    if (status === 100) return "100 - Autorizado o uso da NF-e";

    if (status === 137) return "137 - Nenhum documento localizado";

    if (status === 138) return "138 - Documento localizado para o Destinatário";

    if (status === 140) return "140 - Download disponibilizado";

    if (status === 656) return "656 - Consumo indevido";

    return status;
  };

  return (
    <Card>
      <CardHeader pb="0">
        <HStack justify="space-between">
          <HStack>
            <Icon as={FaRegFileLines} />
            <Heading size="sm">Fiscal</Heading>
          </HStack>
          <ProtectedButton
            permission="installation-card-fiscal-update"
            leftIcon={<FiEdit />}
            variant="ghost"
            size="sm"
            py="1.5rem"
            onClick={openEditFiscalModal}
          >
            Editar
          </ProtectedButton>
        </HStack>
      </CardHeader>

      <CardBody>
        {!!app.fiscal ? (
          <Stack>
            <Text fontWeight="500" color="gray.500">
              Módulos
            </Text>
            <List spacing="0.2rem">
              {!!app.fiscal && (
                <ListItem fontSize="0.9rem" fontWeight="300">
                  <ListIcon as={FaCircleCheck} color="green.500" />
                  Emissão de NF-e
                </ListItem>
              )}

              {!!app.fiscalToken && (
                <ListItem fontSize="0.9rem" fontWeight="300">
                  <ListIcon as={FaCircleCheck} color="green.500" />
                  Emissão de NFC-e
                </ListItem>
              )}
            </List>
          </Stack>
        ) : (
          <Text fontWeight="500" color="gray.500">
            App sem emissão de nota
          </Text>
        )}

        {!!app.fiscalToken && (
          <Stack mt="1rem">
            <Divider my="1rem" />

            <Text fontWeight="500" color="gray.500">
              Token
            </Text>
            <HStack
              p="0.5rem"
              bg="blackAlpha.200"
              rounded="5px"
              cursor="pointer"
              justify="space-around"
              onClick={copyToken}
            >
              <Text w="90%" fontSize="0.9rem">
                {app.fiscalToken}
              </Text>
              <Icon aria-label="Copiar token" as={IoCopyOutline} />
            </HStack>
          </Stack>
        )}

        {!loading && roboFiscal && (
          <Stack mt="1rem">
            <Divider my="1rem" />

            <Text fontWeight="500" color="gray.500">
              Robô de notas
            </Text>

            <TabPanel as={Stack} divider={<StackDivider />} gap="0.5rem">
              <Flex wrap="wrap" rowGap="1.5rem">
                <Stack flexBasis={{ base: "100%", md: "50%", lg: "33.33%" }}>
                  <Text variant="label">Validade do certificado</Text>
                  <Text
                    fontSize="0.8rem"
                    fontWeight="300"
                    color={
                      moment(roboFiscal?.certificadoValidade) >
                      moment().endOf("day")
                        ? "green.400"
                        : "red.400"
                    }
                  >
                    {moment(roboFiscal.certificadoValidade).format(
                      "DD/MM/YYYY"
                    )}{" "}
                    -{" "}
                    {moment(roboFiscal.certificadoValidade).isAfter(
                      moment().endOf("day")
                    )
                      ? "Válido"
                      : "Expirado"}
                  </Text>
                </Stack>

                <Stack
                  flexBasis={{ base: "100%", md: "50%", lg: "33.33%" }}
                  align="flex-start"
                >
                  <Text variant="label">Último status</Text>
                  <Tag
                    size="sm"
                    rounded="full"
                    color={
                      roboFiscal.ultCStat === 100 ||
                      roboFiscal.ultCStat === 137 ||
                      roboFiscal.ultCStat === 138 ||
                      roboFiscal.ultCStat === 140
                        ? "green.400"
                        : "red.400"
                    }
                  >
                    <TagLeftIcon as={FaCircle} />
                    <TagLabel>{checkLastStatus(roboFiscal.ultCStat)}</TagLabel>
                  </Tag>
                </Stack>

                <Stack flexBasis={{ base: "100%", md: "50%", lg: "33.33%" }}>
                  <Text variant="label">Data e hora do último status</Text>
                  <Text fontSize="0.8rem" fontWeight="300">
                    {moment(roboFiscal.dataUltCStat).format(
                      "DD/MM/YYYY HH:mm:ss"
                    )}
                  </Text>
                </Stack>

                <Stack flexBasis={{ base: "100%", md: "50%", lg: "33.33%" }}>
                  <Text variant="label">Criado em</Text>
                  <Text fontSize="0.8rem" fontWeight="300">
                    {moment(roboFiscal.dataInclusao).format("DD/MM/YYYY")}
                  </Text>
                </Stack>

                <Stack flexBasis={{ base: "100%", md: "50%", lg: "33.33%" }}>
                  <Text variant="label">Último NSU</Text>
                  <Text fontSize="0.8rem" fontWeight="300">
                    {roboFiscal.ultNSU}
                  </Text>
                </Stack>

                <Stack flexBasis={{ base: "100%", md: "50%", lg: "33.33%" }}>
                  <Text variant="label">Máximo NSU</Text>
                  <Text fontSize="0.8rem" fontWeight="300">
                    {roboFiscal.maxNSU}
                  </Text>
                </Stack>
              </Flex>
            </TabPanel>
          </Stack>
        )}
      </CardBody>
    </Card>
  );
};

export default Fiscal;
