import { useGlobalContext } from "GlobalContext";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { toast } from "react-toastify";
import {
  load,
  loadDeliveryOrders,
  updateDeliveryOrder,
  verifyOrderPayment,
} from "services/delivery";
import { Mixpanel } from "services/mixpanel";
import { Delivery, IDelivery } from "shared/models/delivery";
import { DeliveryOrder } from "shared/models/deliveryOrder";
import { DeliveryOrderStatus } from "shared/types/deliveryOrder";
import { useAppTabContext } from "../../AppContext";
import DeliveryComponent from "./Delivery";
import EditDeliveryModal from "./components/EditDeliveryModal";

interface DeliveryContextData {
  loadingDelivery: boolean;
  delivery?: Delivery;
  openEditModal(): void;
  updateDeliveryData(d: IDelivery): void;
  loadingOrders: boolean;
  orders: DeliveryOrder[];
  changeOrderStatus(o: DeliveryOrder, s: DeliveryOrderStatus): void;
  statusLabels: { [key: string]: string };
  getVerifyOrderPayment(transactionId: string): void;
}

const statusLabels = {
  CREATING: "Criando",
  PENDING: "Pendente",
  PREPARING: "Em preparo",
  DELIVERED: "Em entrega",
  SCHEDULED: "Agendado",
  FINISHED: "Finalizado",
  CANCELLED: "Cancelado",
  CANCELLATION_REQUESTED: "Cancelamento solicitado",
};

const Context = createContext<DeliveryContextData>({} as DeliveryContextData);

export const useDeliveryContext = () => useContext(Context);

const DeliveryContext = () => {
  const { confirm } = useGlobalContext();
  const { app } = useAppTabContext();

  const [orders, setOrders] = useState<DeliveryOrder[]>([]);
  const [loadingOrders, setLoadingOrders] = useState(false);

  const [delivery, setDelivery] = useState<Delivery>();
  const [loadingDelivery, setLoadingDelivery] = useState(false);

  const [showEditModal, setShowEditModal] = useState(false);

  const openEditModal = () => {
    Mixpanel.track("customers-app-delivery-opened-edit");
    setShowEditModal(true);
  };

  const loadDelivery = useCallback(() => {
    setLoadingDelivery(true);
    load(app.idi)
      .then((response) => {
        setDelivery(new Delivery(response.data));
      })
      .catch((error) => console.error("Falha ao carregar o delivery", error))
      .finally(() => setLoadingDelivery(false));
  }, [app]);

  const loadOrders = useCallback(() => {
    setLoadingOrders(true);
    loadDeliveryOrders(app.idi)
      .then((response) => {
        setOrders(response.data.map((item) => new DeliveryOrder(item)));
      })
      .catch(() => console.error("Falha ao carregar os pedidos"))
      .finally(() => setLoadingOrders(false));
  }, [app]);

  const updateDeliveryData = (data: IDelivery) => {
    setDelivery(new Delivery(data));
  };

  const changeOrderStatus = useCallback(
    (order: DeliveryOrder, status: DeliveryOrderStatus) => {
      confirm(
        `Deseja alterar o status do pedido ${order.id}?`,
        `${statusLabels[order.status]} (${order.status}) para ${
          statusLabels[status]
        } (${status})`,
        () => {
          updateDeliveryOrder(delivery?.idi!, order.id, {
            newStatus: status,
            oldStatus: order.status,
          }).then(() => {
            setOrders(
              orders.map((o) => {
                if (o.id === order.id) o.status = status;
                return o;
              })
            );
            toast.success("Status do pedido alterado");
          });
        }
      );
    },
    [confirm, delivery, orders]
  );

  const getVerifyOrderPayment = useCallback(
    (transactionId: string) => {
      setLoadingOrders(true);
      verifyOrderPayment(app.idi.toString(), transactionId)
        .then(() => {
          loadOrders();
          toast.success("Status de pagamento do pedido atualizado");
        })
        .catch(() => toast.error("Falha ao verificar status de pagamento"))
        .finally(() => setLoadingOrders(false));
    },
    [app.idi, loadOrders]
  );

  useEffect(() => {
    loadDelivery();
  }, [loadDelivery]);

  useEffect(() => {
    loadOrders();
  }, [loadOrders]);

  const data: DeliveryContextData = useMemo(
    () => ({
      delivery,
      loadingDelivery,
      openEditModal,
      updateDeliveryData,
      loadingOrders,
      orders,
      changeOrderStatus,
      statusLabels,
      getVerifyOrderPayment,
    }),
    [
      changeOrderStatus,
      delivery,
      getVerifyOrderPayment,
      loadingDelivery,
      loadingOrders,
      orders,
    ]
  );
  return (
    <Context.Provider value={data}>
      <DeliveryComponent />
      <EditDeliveryModal
        isOpen={showEditModal}
        onClose={() => setShowEditModal(false)}
      />
    </Context.Provider>
  );
};

export default DeliveryContext;
