import { AxiosResponse } from "axios";
import { apiV2 } from "./http";
import {
  AddServicesRequestData,
  CreateContractRequestData,
} from "./interfaces/contract";

export function addServices(
  contractId: number,
  payload: AddServicesRequestData
): Promise<AxiosResponse> {
  return apiV2.post(`contracts/${contractId}/services`, payload);
}

export function create(
  payload: CreateContractRequestData
): Promise<AxiosResponse> {
  return apiV2.post("contracts", payload);
}

export function deleteContract(contractId: number): Promise<AxiosResponse> {
  return apiV2.delete(`contracts/${contractId}`);
}

export function disableContract(contractId: number): Promise<AxiosResponse> {
  return apiV2.get(`contracts/disable/${contractId}`);
}

export function enableContract(contractId: number): Promise<AxiosResponse> {
  return apiV2.get(`contracts/enable/${contractId}`);
}

export function changePaymentMethod(
  contractId: number,
  bank_id: 1 | 2
): Promise<AxiosResponse> {
  return apiV2.post(`contracts/update/${contractId}`, { bank_id });
}
