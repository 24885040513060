import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { searchApps, searchAppsWithYoogaCustomerId } from "services/app";
import { getCustomer } from "services/customer";
import { SearchResponse } from "services/interfaces/search";
import { search } from "services/search";
import { onlyNumbers } from "shared/helpers/string";
import { App } from "shared/models/app";
import { Contract } from "shared/models/contract";
import { Customer } from "shared/models/customer";
import { default as CustomerPage } from "./Customer";
import ContractModal from "./components/ContractModal";
import CreateInstallationModal from "./components/CreateInstallationModal";
import EditCustomerModal from "./components/EditCustomerModal";

interface CustomerContextData {
  customer: Customer;
  apps: App[];
  info: SearchResponse;
  loadCustomer(): void;
  loadApps(result?: SearchResponse): void;
  loading: boolean;
  setEditCustomerModalOpen(b: boolean): void;
  openContractModal(c?: Contract): void;
  setInstallationModalOpen(b: boolean): void;
}

const Context = createContext<CustomerContextData>({} as CustomerContextData);

export const useCustomerContext = () => useContext(Context);

const CustomerContext = () => {
  const navigate = useNavigate();
  const { document } = useParams();
  const { state } = useLocation();
  const [info, setInfo] = useState<SearchResponse>({} as SearchResponse);
  const [customer, setCustomer] = useState<Customer>({} as Customer);
  const [apps, setApps] = useState<App[]>([]);

  const [editCustomerModalOpen, setEditCustomerModalOpen] = useState(false);

  const [contractModalOpen, setContractModalOpen] = useState(false);
  const [contractModalData, setContractModalData] = useState<Contract>();

  const [installationModalOpen, setInstallationModalOpen] = useState(false);

  const [loading, setLoading] = useState(false);
  const [appLoading, setAppLoading] = useState(false);
  const [customerLoading, setCustomerLoading] = useState(false);

  const openContractModal = (contract?: Contract) => {
    if (contract) setContractModalData(contract);
    setContractModalOpen(true);
  };

  const onCloseContractModal = () => {
    setContractModalData(undefined);
    setContractModalOpen(false);
  };

  const onCloseInstallationModal = () => {
    setInstallationModalOpen(false);
  };

  const loadError = useCallback(() => {
    toast.error("Não foi possível ver o cliente");
    navigate("/");
  }, [navigate]);

  const fetch = () => {
    if (!document) return loadError();

    if (state?.result) return postFetch(state.result);

    setLoading(true);
    search(document!).then(async (response) => {
      const result = response.data.find(
        (response) => response.document === document
      );

      if (!result) return loadError();

      await postFetch(result);
    });
  };

  const postFetch = async (result: SearchResponse) => {
    if (result.customer_id) {
      await loadCustomer(result.customer_id);
    }

    setInfo(result);
  };

  const loadApps = useCallback(
    (result?: SearchResponse) => {
      if (!result?.document && !info.document) return;
      const doc = onlyNumbers(result?.document ?? info.document ?? document);

      setAppLoading(true);

      if (customer?.yoogaCustomerId) {
        searchAppsWithYoogaCustomerId(doc, customer.yoogaCustomerId)
          .then((response) => {
            const apps = response.data
              .filter(
                (appData) =>
                  onlyNumbers(appData.cnpj) === doc ||
                  appData.yooga_customer_id === customer.yoogaCustomerId
              )
              .map((appData) => new App(appData));

            setApps(apps);
          })
          .catch(() => toast.error("Falha ao carregar apps"))
          .finally(() => setAppLoading(false));
      } else {
        searchApps(doc)
          .then((response) => {
            setApps(
              response.data
                .filter((appData) => onlyNumbers(appData.cnpj) === doc)
                .map((appData) => new App(appData))
            );
          })
          .catch(() => toast.error("Falha ao carregar apps"))
          .finally(() => setAppLoading(false));
      }
    },
    [info, document, customer]
  );

  const loadCustomer = useCallback(
    (customerId?: number) => {
      return new Promise<void>((resolve, reject) => {
        if (!customer.id && !customerId) {
          resolve();
          return;
        }

        const id = customerId ?? customer.id;

        setCustomerLoading(true);

        getCustomer(id)
          .then((response) => {
            setCustomer(new Customer(response.data));
            resolve();
          })
          .catch((error) => {
            loadError();
            reject(error);
          })
          .finally(() => setCustomerLoading(false));
      });
    },
    [customer, loadError]
  );

  useEffect(() => {
    if (info?.yooga_apps?.length > 0) {
      loadApps(info);
    }
  }, [customer, info, loadApps]);

  useEffect(() => {
    setLoading(appLoading || customerLoading);
  }, [appLoading, customerLoading]);

  useEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const data = useMemo<CustomerContextData>(
    () => ({
      customer,
      apps,
      info,
      loadCustomer,
      loadApps,
      loading,
      setEditCustomerModalOpen,
      openContractModal,
      setInstallationModalOpen,
    }),
    [customer, loadCustomer, loading, apps, info, loadApps]
  );

  return (
    <Context.Provider value={data}>
      <EditCustomerModal
        isOpen={editCustomerModalOpen}
        onClose={() => setEditCustomerModalOpen(false)}
      />
      <ContractModal
        isOpen={contractModalOpen}
        onClose={onCloseContractModal}
        contract={contractModalData}
      />
      <CreateInstallationModal
        isOpen={installationModalOpen}
        onClose={onCloseInstallationModal}
      />
      <CustomerPage />
    </Context.Provider>
  );
};

export default CustomerContext;
