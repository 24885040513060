import { Button, ButtonGroup } from "@chakra-ui/react";
import { Mixpanel } from "services/mixpanel";
import { orderStatus } from "shared/models/order";
import { useOrderModalContext } from "../ModalContext";

const OrderActions = () => {
  const {
    editing,
    startEditing,
    loading,
    order,
    abortEditing,
    processPayment,
    cancelOrder,
    generateOrderInvoice,
  } = useOrderModalContext();

  const downloadInvoice = () => {
    Mixpanel.track("customers-financial-order-clicked-download-invoice");
    window.open(order?.invoiceLink);
  };

  return order?.statusId !== orderStatus.CANCELADO &&
    order?.statusId !== orderStatus.PAGO ? (
    <ButtonGroup size="sm" variant="filled" mt="1rem" isDisabled={loading}>
      {editing ? (
        <>
          <Button variant="outlined" onClick={abortEditing}>
            Cancelar Edição
          </Button>
        </>
      ) : (
        <>
          {!order?.invoice && !order?.invoices.length && (
            <Button onClick={startEditing}>Editar Cobrança</Button>
          )}

          {order?.canGenerateInvoice && (
            <Button onClick={generateOrderInvoice}>Gerar boleto</Button>
          )}

          {order?.invoiceLink && (
            <Button onClick={downloadInvoice}>Baixar boleto</Button>
          )}

          {order?.bankId === 1 && order.statusId === 1 && (
            <Button onClick={processPayment}>Retentar Cobrança</Button>
          )}

          <Button onClick={cancelOrder}>Cancelar Cobrança</Button>
        </>
      )}
    </ButtonGroup>
  ) : (
    <></>
  );
};

export default OrderActions;
