export interface IRoboFiscal {
  id: number;
  xNome: string;
  certificadoValidade: Date;
  manifestar: number;
  validade: Date;
  ultCStat: number;
  dataUltCStat: Date;
  ultNSU: number;
  maxNSU: number;
  erroNSU: number;
  dataNSU: Date;
  key: string;
  dataInclusao: Date;
  ativo: null;
  cnpj: string;
}

export default class RoboFiscal {
  id: number;
  xNome: string;
  certificadoValidade: Date;
  manifestar: number;
  validade: Date;
  ultCStat: number;
  dataUltCStat: Date;
  ultNSU: number;
  maxNSU: number;
  erroNSU: number;
  dataNSU: Date;
  key: string;
  dataInclusao: Date;
  ativo: null;
  cnpj: string;

  constructor(data: IRoboFiscal) {
    this.id = data.id;
    this.xNome = data.xNome;
    this.certificadoValidade = data.certificadoValidade;
    this.manifestar = data.manifestar;
    this.validade = data.validade;
    this.ultCStat = data.ultCStat;
    this.dataUltCStat = data.dataUltCStat;
    this.ultNSU = data.ultNSU;
    this.maxNSU = data.maxNSU;
    this.erroNSU = data.erroNSU;
    this.dataNSU = data.dataNSU;
    this.key = data.key;
    this.dataInclusao = data.dataInclusao;
    this.ativo = data.ativo;
    this.cnpj = data.cnpj;
  }
}
